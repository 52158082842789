@value makerBlue from "../../common/color.css";

.modal {
  position: absolute;
  left: 40%;
  top: 130vh;
  /* bottom: 1/%; */
  background-color:#ededed;
  width: 25rem;
  height: 15rem;
  border-radius:4px;
  border: solid 1px makerBlue;
  color: makerBlue;
  animation: smoothOn 250ms
}

.text {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
}

/* -------------X button ------------ */

.sect01 {
  color: makerBlue;
  position: relative;
  width: 1.5rem; /* X 사이즈 */
  height: 4rem; /* X 세로 중앙 */
  left: 90%;
  cursor: pointer;
}

.sect01:hover {
  transform: scale(1.2);
  transition: 300ms;
}

.sect01 .lineBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.sect01 .lineBox > span {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 4px;
  background-color: makerBlue;
}

.sect01 .line01 {
  transform: rotate(135deg) translateX(0%);
}

.sect01 .line02 {
  transform: rotate(45deg) translateX(0%);
}


@keyframes smoothOn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}