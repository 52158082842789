@value makerWhite, makerGray, makerBlue from "../../common/color.css";
@value makerSmall, makerMedium, makerLarge from "../../common/size.css";

/*--------------------  Banner ------------------*/
.banner {
  background-image: none;
}

.banner {
  background-image: url("/src/background-img/contact.jpg");
  height: 90vh;
  padding-left: 7vw;
  padding-top: 15vh;
}

.contactTitle {
  text-align: center;
}

.mainTitle {
  font-size: 5rem;
  color: makerWhite;
  animation: smoothAppear2 0.5s;
}

.subTitle {
  font-size: makerSmall;
  color: makerWhite;
  animation: smoothAppear2 0.8s;
}
.contactUs{
  /* padding: 4rem; */
  height: 60vh;
}

.contactTitle {
  color:makerBlue;
  font-size: makerLarge;
}

.textBox {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.imageBox {
  animation: smoothAppear3 0.8s;
}

.text {
  margin:0;
}

.inputBox {
  display: flex;
  flex-direction: column;
  padding-left:5rem;
  animation: smoothAppear2 1s;
}

.input {
  margin: 0rem 0rem 1rem 0rem;
  height: 2rem;
  width: 30rem;
}

.submit {
  background-color: makerBlue;
  border: none;
  color: makerWhite;
  height: 2rem;
  cursor: pointer;
  font-size: makerSmall;
  margin-top:1rem;
}


/* --------------unmount----------- */
.textBox1 {
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: 500ms;
}

.imageBox1 {
  animation: unmount3 1s;
}
.inputBox1 {
  display: flex;
  flex-direction: column;
  padding-left:5rem;
  animation: unmount2 1s;
}

@keyframes unmount1 {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20rem);
  }
}

@keyframes unmount2 {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20rem);
  }
}
@keyframes unmount3 {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-20rem);
  }
}

/* ----------mount -------------- */
@keyframes smoothAppear1 {
  from {
    opacity: 0;
    transform: translateY(20rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes smoothAppear2 {
  from {
    opacity: 0;
    transform: translateX(20rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes smoothAppear3 {
  from {
    opacity: 0;
    transform: translateX(-20rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
