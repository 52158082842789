@value makerWhite, makerBlue from "../../common/color.css";

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 0.5rem;
  align-items: center;
  z-index: 2;
  height: 10vh;
}

.headerOn {
  background-color: makerBlue;
  transition:300ms;
}

.contents {
  color:makerWhite;
  display: flex;
  padding-right:6vw;
}

.content {
  opacity: 0.5;
  padding: 1rem;
  font-weight: bold;
  font-size: larger;
  margin-left: 4rem;
}

.content:hover {
  opacity: 1;
  transform: scale(1.1);
  transition: 300ms;
}

.logo {
  display: flex;
  align-items: center;
}

.logoTitle {
  color:makerWhite;
  margin-left: 6vw;
}

.homeImage {
  cursor: pointer;
  padding:1rem;
  width: 1.7rem;
  height: 1.7rem;
}

.homeImage:hover {
  transform: scale(1.2);
  transition: 300ms;
}